import 'bootstrap';
import './scss/app.scss';
import './scss/main.scss';
import './scss/main-responsive.scss';

import Swiper from 'swiper';
import ScrollMagic from 'scrollmagic';

require('jquery.easing');

$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

//Collapsible
$(function () {
    var element = document.querySelectorAll('[data-collapsible="true"]');

    for (var i = 0; i < element.length; i++) {
        element[i].onclick = function (e) {
            e.preventDefault();

            var that = this,
                thatParent = that.parentNode;

            $(element).parent().siblings().find($(element).not(that)).removeClass('is-active');
            $(element).parent().siblings().find('.collapsible-content').css({
                maxHeight: '',
            });

            if (!that.classList.contains('is-active')) {
                that.classList.add('is-active');
                thatParent.querySelector('.collapsible-content').style.maxHeight = thatParent.querySelector('.collapsible-content').scrollHeight + 'px';
            } else {
                that.classList.remove('is-active');
                thatParent.querySelector('.collapsible-content').style.maxHeight = '';
            }
        }
    }
});

//Mobile Navigation
$(function () {
    var trigger = $('[data-trigger="mobile-navigation"], button[data-trigger="mobile-navigation"]'),
        close = $('[data-dismiss="mobile-navigation"], div[data-dismiss="mobile-navigation"]'),
        mobileMenu = $('.mobile-menu'),
        body = $('body');

    function slideOpen(e) {
        if (!mobileMenu.hasClass('mobile-navigation-active')) {
            body.addClass('mobile-navigation-is-active');
            mobileMenu.addClass('mobile-navigation-active');
        } else {
            body.removeClass('mobile-navigation-is-active');
            mobileMenu.removeClass('mobile-navigation-active');
        }
    }

    function slideClose(e) {
        body.removeClass('mobile-navigation-is-active');
        mobileMenu.removeClass('mobile-navigation-active');
    }

    trigger.on('click', slideOpen);
    close.on('click', slideClose);
});

//Sub navigation
$(function () {
    var navigation = $('.sub-navigation'),
        content = $('.sub-navigation-content'),
        mobileNavigation = $('.mobile-sub-navigation'),
        body = $('body'),
        trigger = $('a[data-trigger="sub-nav"]'),
        target;

    var a, b;

    function showNavigation(e) {
        if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
            $(e.target).addClass('active');
        } else {
            $('a[data-target="' + $(e.target).closest('.sub-navigation').find(content).attr('id') + '"').addClass('active');
        }
        body.addClass('sub-navigation-is-open');
        navigation.show();
        setTimeout(function () {
            navigation.addClass('sub-navigation-open');
        }, 50);

        target = $('#' + $(e.target).data('target'));

        target.siblings().hide();
        target.show();
    }

    function hideNavigation(e) {
        b = setTimeout(function () {
            body.removeClass('sub-navigation-is-open');
            navigation.removeClass('sub-navigation-open');
            a = setTimeout(function () {
                navigation.hide();
            }, 300);

            trigger.removeClass('active');
        }, 200);
    }

    function initMenu() {
        if ($(window).width() < 768) {
            if (!body.hasClass('has-mobile-navigation')) {
                body.addClass('has-mobile-navigation');

                body.off('mouseover');

                body.on('click', function (e) {
                    clearTimeout(a);
                    clearTimeout(b);

                    if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                        target = $(e.target).parent().find(mobileNavigation);

                        e.preventDefault();

                        if (!target.hasClass('mobile-sub-navigation-open')) {
                            $(e.target).addClass('active');
                            target.addClass('mobile-sub-navigation-open');
                        } else {
                            $(e.target).removeClass('active');
                            target.removeClass('mobile-sub-navigation-open');
                        }
                    } else {
                        $(e.target).removeClass('active');
                    }
                });
            }
        } else {
            body.removeClass('has-mobile-navigation');

            body.off('click');

            body.on('click', function (e) {
                clearTimeout(a);
                clearTimeout(b);

                if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                    return true;
                }
            });

            body.on('mouseover', function (e) {
                clearTimeout(a);
                clearTimeout(b);

                if (trigger.is(e.target) || trigger.has(e.target).length > 0 || navigation.is(e.target) || navigation.has(e.target).length > 0) {
                    showNavigation(e);
                } else {
                    hideNavigation(e);
                }
            });
        }
    }

    initMenu();
    $(window).on('resize', initMenu);
});

//Custom Dropdown
$(function () {
    var dropdown = $('.custom-dropdown'),
        body = $('body'),
        trigger = $('a[data-trigger="custom-dropdown"]'),
        target;

    var a, b;

    function showNavigation(e) {
        target = $(e.target).parent().find(dropdown);

        $(e.target).parent().siblings().find(dropdown).hide();
        $(e.target).parent().siblings().find(dropdown).removeClass('custom-dropdown-open');

        $(e.target).closest('.nav-dropdown').find(trigger).addClass('custom-dropdown-is-open');

        target.show();
        setTimeout(function () {
            target.addClass('custom-dropdown-open');
        }, 50);
    }

    function hideNavigation() {
        trigger.removeClass('custom-dropdown-is-open');

        b = setTimeout(function () {
            dropdown.removeClass('custom-dropdown-open');
            a = setTimeout(function () {
                dropdown.hide();
            }, 300);
        }, 150);
    }

    function initMenu() {
        if ($(window).width() < 768) {
            if (!body.hasClass('has-mobile-navigation')) {
                body.addClass('has-mobile-navigation');

                body.off('mouseover');

                body.on('click', function (e) {
                    clearTimeout(a);
                    clearTimeout(b);

                    if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                        target = $(e.target).parent().find(dropdown);

                        e.preventDefault();

                        $(e.target).parent().siblings().find(dropdown).hide();
                        $(e.target).parent().siblings().find(dropdown).removeClass('custom-dropdown-open');
                        $(e.target).closest('.nav-dropdown').find('.nav-link').removeClass('custom-dropdown-is-open');

                        if (!target.hasClass('custom-dropdown-open')) {
                            target.show();
                            target.addClass('custom-dropdown-open');
                        } else {
                            $(e.target).parent().find(dropdown).hide();
                            $(e.target).parent().find(dropdown).removeClass('custom-dropdown-open');
                            $(e.target).closest('.nav-dropdown').find(trigger).removeClass('custom-dropdown-is-open');
                        }
                    }
                });
            }
        } else {
            body.removeClass('has-mobile-navigation');

            body.off('click');

            body.on('click', function (e) {
                clearTimeout(a);
                clearTimeout(b);

                if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                    return true;
                }
            });

            body.on('mouseover', function (e) {
                clearTimeout(a);
                clearTimeout(b);

                if (trigger.is(e.target) || trigger.has(e.target).length > 0 || dropdown.is(e.target) || dropdown.has(e.target).length > 0) {
                    showNavigation(e);
                } else {
                    hideNavigation();
                }
            });
        }
    }

    $(window).on('resize', initMenu);
    initMenu();
});

//Load single div backgrounds
$(function () {
    var image = document.querySelectorAll('img[data-bg="true"], [data-bg="true"]');

    for (var i = 0; i < image.length; i++) {
        (function (n) {
            var img = new Image();

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = image[n].src;

            function imageFound() {
                image[n].parentNode.style.backgroundImage = 'url(' + img.src + ')';

                if ($(image[n]).parent().is('.bg')) {
                    $(image[n]).parent().css({
                        opacity: '1'
                    });
                }
            }

            function imageNotFound() {
                image[n].parentNode.style.backgroundColor = '#F8F9FD';
            }
        }(i));
    }
});

//Load section backgrounds
$(function () {
    var section = document.querySelectorAll('div[data-section-bg], section[data-section-bg], [data-section-bg]');

    for (var i = 0; i < section.length; i++) {
        (function (n) {
            var img = new Image();
            var sectionBG = document.createElement('div');

            sectionBG.classList.add('section-bg');

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = section[n].getAttribute('data-section-bg');

            function imageFound() {
                section[n].append(sectionBG);
                sectionBG.style.backgroundImage = 'url(' + img.src + ')';
            }

            function imageNotFound() {
                sectionBG.style.backgroundColor = '#F8F9FD';
            }
        }(i));
    }
});

//Swiper - Testimonials
$(function () {
    var swiperWrapper = $('.swiper-testimonials-wrapper');

    if (swiperWrapper.length > 0) {
        var swiper = new Swiper('.swiper-testimonials', {
            slidesPerView: '3',
            navigation: {
                prevEl: '.swiper-testimonials-wrapper .swiper-btn-prev',
                nextEl: '.swiper-testimonials-wrapper .swiper-btn-next',
            },
            pagination: {
                el: '.swiper-testimonials-wrapper .swiper-pagination',
                clickable: true,
            },
            spaceBetween: 15,
            breakpoints: {
                0: {
                    slidesPerView: '1',
                    spaceBetween: 15,
                },
                576: {
                    slidesPerView: '1',
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: '2',
                    spaceBetween: 15,
                    noSwipingClass: 'swiper-testimonials',
                },
                1024: {
                    slidesPerView: '3',
                    spaceBetween: 30,
                }
            }
        });
    }
});

//Swiper - Insights
$(function () {
    var swiperWrapper = $('.swiper-insights-wrapper'),
        insightsLi = $('.ul-insights li');

    if (swiperWrapper.length > 0) {
        var swiper = new Swiper('.swiper-insights', {
            slidesPerView: 1,
            autoHeight: true,
            pagination: {
                el: '.swiper-insights-wrapper .swiper-pagination',
                clickable: true,
            },
            spaceBetween: 15,
            on: {
                slideChange: function () {
                    var activeIndex = swiper.realIndex;

                    insightsLi.removeClass('is-active');
                    insightsLi.eq(activeIndex).addClass('is-active');
                },
            },
        });

        if ($(window).width() < 768) {
            insightsLi.on('click', function () {
                var that = $(this);

                swiper.slideTo(that.index());
            });
        }

        $(window).on('resize', function () {
            if ($(window).width() < 768) {
                insightsLi.on('click', function () {
                    var that = $(this);

                    swiper.slideTo(that.index());
                });
            }
        });
    }
});

//Swiper - Values
$(function () {
    var swiperWrapper = $('.swiper-values-wrapper');

    if (swiperWrapper.length > 0) {
        var swiper = new Swiper('.swiper-values', {
            slidesPerView: '3',
            navigation: {
                prevEl: '.swiper-values-wrapper .swiper-btn-prev',
                nextEl: '.swiper-values-wrapper .swiper-btn-next',
            },
            spaceBetween: 30,
            breakpoints: {
                0: {
                    slidesPerView: '1',
                    spaceBetween: 0,
                },
                576: {
                    slidesPerView: '1',
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: '2',
                    spaceBetween: 15,
                    noSwipingClass: 'swiper-values'
                },
                1024: {
                    slidesPerView: '3',
                    spaceBetween: 30,
                }
            }
        });
    }
});

//Swiper - People Data Media
$(function () {
    var swiperWrapper = $('.swiper-people-data-media-wrapper');

    if (swiperWrapper.length > 0) {
        var swiper = new Swiper('.swiper-people-data-media', {
            slidesPerView: 'auto',
            spaceBetween: 15,
            autoHeight: true,
            pagination: {
                el: '.swiper-people-data-media-wrapper .swiper-pagination',
                clickable: true,
            },
        });
    }
});

//Form control
$(function () {
    let $formInput = $('input, textarea, select');

    $formInput.on('focus', function () {
        let that = $(this);

        that.parent().addClass('is-focus');
    });

    $formInput.on('blur', function () {
        let that = $(this);

        if (that.is('input') || that.is('textarea')) {
            if (that.val().length === 0) {
                that.parent().removeClass('is-focus');
            } else {
                that.parent().addClass('is-filled');
                that.parent().removeClass('is-focus');
            }
        } else {
            that.parent().removeClass('is-focus');
        }
    }).trigger('blur');

    $formInput.on('keyup keydown', function () {
        let that = $(this);

        if (that.val().length === 0) {
            that.parent().removeClass('is-filled');
        } else {
            that.parent().addClass('is-filled');
        }
    });
});

$(function () {
    var element = $('[data-animate="true"]'),
        updatePage,
        animateElements;

    updatePage = function () {
        window.requestAnimationFrame(function () {
            animateElements();
        });
    };

    animateElements = function () {
        $('section, .section').each(function () {
            $(this).find(element).each(function (i) {
                var that = $(this);

                if (that.isInViewport()) {
                    that.css({
                        transitionDelay: 80 * i + 'ms',
                    });
                }
            });
        });
    };

    setInterval(updatePage, 10);
});

//Scroll Reveal
$(function () {
    // init controller
    var controller = new ScrollMagic.Controller();

    // build scenes
    var revealElements = document.querySelectorAll("[data-animate='true']");

    for (var i = 0; i < revealElements.length; i++) { // create a scene for each element
        var scene = new ScrollMagic.Scene({
            triggerElement: revealElements[i], // y value not modified, so we can use element as trigger as well
            // offset: $(window).height() / 12,	// start a little later
            offset: 40,	// start a little later
            triggerHook: 1,
        })
            .setClassToggle(revealElements[i], "animate") // add class toggle
            .reverse(false)
            .addTo(controller);

        function callback() {
            $('section, .section').each(function () {
                $(this).find($(revealElements)).each(function (i) {
                    var that = $(this);

                    that.css({
                        transitionDelay: 100 * i + 'ms',
                    });
                });
            });
        }

        // add listeners
        scene.on("enter", callback);
    }
});

//Smooth Scroll
$(function () {
    // Select all links with hashes
    $('[data-scroll="smooth"], a[data-scroll="smooth"]').click(function (event) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top - $('header').innerHeight()
            }, 1000, 'easeInOutQuint', function () {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                } else {
                    $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                }
            });
        }
    });
});

//Language selector redirect
$(function () {
    var langSelector = $('.language-selector select'),
        langOptionTarget;

    langSelector.on('change', function (e) {
        var that = $(e.target);

        langOptionTarget = $(that[0].options[that[0].selectedIndex]).data('href');

        if (that[0].options[that[0].selectedIndex].hasAttribute('data-href') && $(that[0].options[that[0].selectedIndex]).data('href').length > 0) {
            window.open(langOptionTarget, '_self');
        }
    });
});

//Custom dropdown
$(function () {
    let $trigger = $('div[data-toggle="select-box"]'),
        $option = $('.select-box-custom-option');

    function toggleSelect() {
        let $this = $(this);

        $('.select-box-custom').not($this.closest('.select-box-custom')).removeClass('active');

        if (!$this.closest('.select-box-custom').hasClass('active')) {
            $this.closest('.select-box-custom').addClass('active');
        } else {
            $this.closest('.select-box-custom').removeClass('active');
        }
    }

    function selectOption(e) {
        e.preventDefault();
        let $this = $(this);

        if ($this.closest('.select-box-custom').find('.select-box-custom-selected').length) {
            if ($this[0].hasAttribute('data-lang')) {
                $this.closest('.select-box-custom').find('.select-box-custom-selected').text($this.data('lang'));
            } else {
                $this.closest('.select-box-custom').find('.select-box-custom-selected').text($this.text());
            }
        }

        if ($this[0].hasAttribute('data-href')) {
            window.open($this.data('href'), '_self');
        }

        //Update <select> value
        $this.closest('.select-box-custom').next().val($this.closest('.select-box-custom').find('.select-box-custom-dropdown').find('li').eq($this.index()).text());

        //Remove "active' class from custom select
        $('.select-box-custom').removeClass('active');
    }

    $(document).on('mouseup', function (e) {
        let container = $('.select-box-custom');

        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.removeClass('active');
        }
    });

    $(document).on('keydown', function (e) {
        let keyCode = e.keyCode || e.which;

        if (keyCode == 9) {
            if ($('.select-box-custom').hasClass('active')) {
                $('.select-box-custom').removeClass('active');
            }
        }
    });

    $(document).on('click', 'div[data-toggle="select-box"]', toggleSelect);
    $(document).on('click', '.select-box-custom-option', selectOption);
    $trigger.click(toggleSelect);
    $option.click(selectOption);
});

//Replace old select with custom dropdown
$(function () {
    let $field = $('select[class*="gfield_select"], select, .select');

    $field.each(function () {
        let $this = $(this);

        var placeholder = $this.data('placeholder') !== undefined ? $this.data('placeholder') : $this.find('option:first-child').text() || $this.parent().hasClass("language-selector") ? $this.find('option').data('lang') : $this.data('placeholder');

        let $template = $('<div class="select-box-custom">' +
            '    <div class="select-box-custom-selected" data-toggle="select-box">' + placeholder + '</div>' +
            '    <ul class="select-box-custom-dropdown">' +
            '    </ul>' +
            '</div>');

        $template.insertBefore($this);
        $this.find('option').each(function () {
            let $thisOption = '<li class="select-box-custom-option">' + $(this).text() + '</li>';

            $this.prev().find('.select-box-custom-dropdown').append($thisOption);

            if ($(this)[0].hasAttribute('data-href')) {
                $this.prev().find('.select-box-custom-dropdown').find('.select-box-custom-option').eq($(this).index()).attr('data-href', $(this).data('href'));
            }

            if ($(this)[0].hasAttribute('data-lang')) {
                $this.prev().find('.select-box-custom-dropdown').find('.select-box-custom-option').eq($(this).index()).attr('data-lang', $(this).data('lang'));
            }
        });
    });
});

//Modal
$(function () {
    const trigger = $('a[data-trigger="f-modal"], div[data-trigger="f-modal"], button[data-trigger="f-modal"], [data-trigger="f-modal"]');
    const close = $('.f-modal-close');
    const modal = $('.f-modal');
    const body = $('body');

    var a, b;

    function openModal(e) {
        e.preventDefault();

        var $this = $(this),
            target = $($this.attr('data-href'));

        body.removeClass('f-modal-is-open');
        modal.removeClass('f-modal-open');
        modal.hide();
        clearTimeout(b);

        if (!body.hasClass('f-modal-is-open')) {
            target.show();

            a = setTimeout(function () {
                body.addClass('f-modal-is-open');
                target.addClass('f-modal-open');
            }, 50);
        }
    }

    function closeModal() {
        body.removeClass('f-modal-is-open');
        modal.removeClass('f-modal-open');

        b = setTimeout(function () {
            modal.hide();
        }, 400);
    }

    trigger.on('click', openModal);
    close.on('click', closeModal);
});

//Enlarge
$(function () {
    const trigger = $('a[data-trigger="enlarge"], [data-trigger="enlarge"]');
    const close = $('.f-modal-close');
    const modal = $('.f-modal');
    const body = $('body');

    var a, b;

    var template;

    function openModal(e) {
        e.preventDefault();

        var $this = $(this);

        var image = $('<img alt="" src="' + $this.data('enlarge-src') + '" />');

        template = $('<div class="f-modal f-modal-enlarge">' +
            '<div class="f-modal-table">' +
            '<div class="f-modal-cell">' +
            '<div class="f-modal-content">' +
            '<div class="f-modal-close"></div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>');

        template.appendTo(body);
        image.appendTo(template.find('.f-modal-content'));

        body.removeClass('f-modal-is-open');
        modal.removeClass('f-modal-open');
        modal.hide();
        clearTimeout(b);

        if (!body.hasClass('f-modal-is-open')) {
            template.show();

            a = setTimeout(function () {
                body.addClass('f-modal-is-open');
                template.addClass('f-modal-open');
            }, 50);
        }
    }

    function closeModal() {
        body.removeClass('f-modal-is-open');
        template.removeClass('f-modal-open');

        b = setTimeout(function () {
            template.hide();
            template.children().remove();
            template.remove();
        }, 400);
    }

    trigger.on('click', openModal);
    $(document).on('click', '.f-modal-close', closeModal);
});

//Insights Interaction
$(function () {
    var element = $('.insights-hidden-content-slide'),
        trigger = $('.ul-insights li'),
        target,
        body = $('body');

    var a, b;

    function toggleSlide() {
        var that = $(this);

        clearTimeout(a);

        a = setTimeout(function () {
            element.removeClass('is-visible');
            element.eq(that.index()).addClass('is-visible');
        }, 300);

        trigger.removeClass('is-active');
        that.addClass('is-active');
    }

    function initMenu() {
        if ($(window).width() < 768) {
            if (!body.hasClass('has-mobile-insights')) {
                body.addClass('has-mobile-insights');

                body.off('mouseover');

                body.on('click', function (e) {
                    if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                        e.preventDefault();

                        clearTimeout(a);

                        a = setTimeout(function () {
                            element.removeClass('is-visible');
                            element.eq($(e.target).closest('li').index()).addClass('is-visible');
                        }, 300);

                        trigger.removeClass('is-active');
                        $(e.target).closest('li').addClass('is-active');
                    }
                });
            }
        } else {
            body.removeClass('has-mobile-insights');

            body.off('click');

            body.on('click', function (e) {
                if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                    return true;
                }
            });

            body.on('mouseover', function (e) {
                if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                    clearTimeout(a);

                    a = setTimeout(function () {
                        element.removeClass('is-visible');
                        element.eq($(e.target).closest('li').index()).addClass('is-visible');
                    }, 300);

                    trigger.removeClass('is-active');
                    $(e.target).closest('li').addClass('is-active');
                }
            });
        }
    }

    $(window).on('resize', initMenu);
    initMenu();
});

//People data - scroll
$(function () {
    // init controller
    var controller = new ScrollMagic.Controller();

    var slide = $('.people-data-content-slide'),
        slideTitle = $('.people-data-title').closest('li'),
        wrapper = $('.section-people-data-grid');

    var scene;

    function sceneInit() {
        scene = new ScrollMagic.Scene({
            triggerElement: '.section-people-data-grid',
            duration: 5000,
            triggerHook: 0,
        })
            .setPin(".section-people-data-grid")
            .reverse(true)
            .addTo(controller);

        $('.people-data-content-holder').height(slide.eq(0).innerHeight());
        slide.eq(0).addClass('is-active');

        slideTitle.on('click', function() {
            var that = $(this),
                currentIndex = that.index();

            var increment = 0.2;

            if (currentIndex === 0) {
                scene.progress(0);
            } else {
                scene.progress(0.4 + increment * currentIndex);
            }

            slideTitle.find('a').removeClass('active');
            that.find('a').addClass('active');
        });

        scene.on('progress', function (event) {
            if (event.progress >= 0) {
                slide.addClass('is-hidden');

                $('.people-data-content-holder').height(slide.eq(0).innerHeight());
                slide.eq(0).removeClass('is-hidden').addClass('is-active');

                slideTitle.find('a').removeClass('active');
                slideTitle.eq(0).find('a').addClass('active');
            }

            if (event.progress >= 0.2) {
                slide.addClass('is-hidden');

                $('.people-data-content-holder').height(slide.eq(1).innerHeight());
                slide.eq(1).removeClass('is-hidden').addClass('is-active');

                slideTitle.find('a').removeClass('active');
                slideTitle.eq(0).find('a').addClass('active');
            }

            if (event.progress >= 0.4) {
                slide.addClass('is-hidden');

                $('.people-data-content-holder').height(slide.eq(2).innerHeight());
                slide.eq(2).removeClass('is-hidden').addClass('is-active');

                slideTitle.find('a').removeClass('active');
                slideTitle.eq(0).find('a').addClass('active');
            }

            if (event.progress >= 0.6) {
                slide.addClass('is-hidden');

                $('.people-data-content-holder').height(slide.eq(3).innerHeight());
                slide.eq(3).removeClass('is-hidden').addClass('is-active');

                slideTitle.find('a').removeClass('active');
                slideTitle.eq(1).find('a').addClass('active');
            }

            if (event.progress >= 0.8) {
                slide.addClass('is-hidden');

                $('.people-data-content-holder').height(slide.eq(4).innerHeight());
                slide.eq(4).removeClass('is-hidden').addClass('is-active');

                slideTitle.find('a').removeClass('active');
                slideTitle.eq(2).find('a').addClass('active');
            }
        });
    }

    function initPeopleData() {
        if ($(window).width() > 1023) {
            if (!wrapper.hasClass('has-scene')) {
                wrapper.addClass('has-scene');

                sceneInit();
            } else {
                scene = scene.destroy(true);

                sceneInit();
            }
        } else {
            if (wrapper.hasClass('has-scene')) {
                wrapper.removeClass('has-scene');
                scene = scene.destroy(true);
            }
        }
    }

    if (wrapper.length > 0) {
        initPeopleData();
        $(window).on('resize', initPeopleData);
    }
});

